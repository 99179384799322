/**
 * Get the top position fot he element relative to window top
 *
 * @param {(HTMLElement | null)} element
 * @returns {*}  {number}
 */
export const getElementTopPosition = (element: HTMLElement | null): number => {
    return element ? element.offsetTop + getElementTopPosition(element.offsetParent as HTMLElement) : 0;
};

/**
 * Scrolls the window to bring the specified element into view.
 *
 * @param {any} element - The element to scroll into view.
 *
 * @param {number} duration - The duration of the scroll animation in milliseconds.
 *
 * @returns {void}
 */
export const scrollIntoView = (element: any, duration = 0.7) => {
    if (!element) return;

    const start = window.pageYOffset;
    const target = getElementTopPosition(element as HTMLElement) - 200;
    const distance = target - start;
    let currentTime = 0;

    /**
     * Timer function
     *
     * @param {number} t
     * @param {number} b
     * @param {number} c
     * @param {number} d
     * @returns {number}
     */
    const easeInOutQuad = (t: number, b: number, c: number, d: number) => {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    /**
     * Animates scrolling to a specific position on the page using the easing function easeInOutQuad.
     *
     * @function animateScroll
     * @returns {void}
     */
    const animateScroll = () => {
        currentTime += 1 / 60;

        const newPosition = easeInOutQuad(currentTime, start, distance, duration);
        window.scrollTo(0, newPosition);

        if (currentTime < duration) {
            requestAnimationFrame(animateScroll);
        }
    };

    requestAnimationFrame(animateScroll);
};
