// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ysduYMftytPY9W9h8T9r .spinner_1KD7 {
  animation: t3tsdVS8RZKEv35RECAQ 1.2s infinite;
}
.ysduYMftytPY9W9h8T9r .spinner_MJg4 {
  animation-delay: 0.1s;
}
.ysduYMftytPY9W9h8T9r .spinner_sj9X {
  animation-delay: 0.2s;
}
.ysduYMftytPY9W9h8T9r .spinner_WwCl {
  animation-delay: 0.3s;
}
.ysduYMftytPY9W9h8T9r .spinner_vy2J {
  animation-delay: 0.4s;
}
.ysduYMftytPY9W9h8T9r .spinner_os1F {
  animation-delay: 0.5s;
}
.ysduYMftytPY9W9h8T9r .spinner_l1Tw {
  animation-delay: 0.6s;
}
.ysduYMftytPY9W9h8T9r .spinner_WNEg {
  animation-delay: 0.7s;
}
.ysduYMftytPY9W9h8T9r .spinner_kugV {
  animation-delay: 0.8s;
}
.ysduYMftytPY9W9h8T9r .spinner_4zOl {
  animation-delay: 0.9s;
}
.ysduYMftytPY9W9h8T9r .spinner_7he2 {
  animation-delay: 1s;
}
.ysduYMftytPY9W9h8T9r .spinner_SeO7 {
  animation-delay: 1.1s;
}
@keyframes t3tsdVS8RZKEv35RECAQ {
  0%, 50% {
    animation-timing-function: cubic-bezier(0.27, 0.42, 0.37, 0.99);
    r: 0;
  }
  25% {
    animation-timing-function: cubic-bezier(0.53, 0, 0.61, 0.73);
    r: 2px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Loader/Style.module.scss"],"names":[],"mappings":"AAGY;EACI,6CAAA;AAFhB;AAMQ;EACI,qBAAA;AAJZ;AAOQ;EACI,qBAAA;AALZ;AAQQ;EACI,qBAAA;AANZ;AASQ;EACI,qBAAA;AAPZ;AAUQ;EACI,qBAAA;AARZ;AAWQ;EACI,qBAAA;AATZ;AAYQ;EACI,qBAAA;AAVZ;AAaQ;EACI,qBAAA;AAXZ;AAcQ;EACI,qBAAA;AAZZ;AAeQ;EACI,mBAAA;AAbZ;AAgBQ;EACI,qBAAA;AAdZ;AAiBQ;EACI;IAEI,+DAAA;IACA,IAAA;EAhBd;EAmBU;IACI,4DAAA;IACA,MAAA;EAjBd;AACF","sourcesContent":[".styles {\n    :global {\n        .spinner_1KD7 {\n            :local {\n                animation: spinner_6QnB 1.2s infinite;\n            }\n        }\n\n        .spinner_MJg4 {\n            animation-delay: 0.1s;\n        }\n\n        .spinner_sj9X {\n            animation-delay: 0.2s;\n        }\n\n        .spinner_WwCl {\n            animation-delay: 0.3s;\n        }\n\n        .spinner_vy2J {\n            animation-delay: 0.4s;\n        }\n\n        .spinner_os1F {\n            animation-delay: 0.5s;\n        }\n\n        .spinner_l1Tw {\n            animation-delay: 0.6s;\n        }\n\n        .spinner_WNEg {\n            animation-delay: 0.7s;\n        }\n\n        .spinner_kugV {\n            animation-delay: 0.8s;\n        }\n\n        .spinner_4zOl {\n            animation-delay: 0.9s;\n        }\n\n        .spinner_7he2 {\n            animation-delay: 1s;\n        }\n\n        .spinner_SeO7 {\n            animation-delay: 1.1s;\n        }\n\n        @keyframes spinner_6QnB {\n            0%,\n            50% {\n                animation-timing-function: cubic-bezier(0.27, 0.42, 0.37, 0.99);\n                r: 0;\n            }\n\n            25% {\n                animation-timing-function: cubic-bezier(0.53, 0, 0.61, 0.73);\n                r: 2px;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"styles": `ysduYMftytPY9W9h8T9r`,
	"spinner_6QnB": `t3tsdVS8RZKEv35RECAQ`
};
export default ___CSS_LOADER_EXPORT___;
