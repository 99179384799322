import styles from './Style.module.scss';
/**
 * Component loader
 *
 * @returns {*}  {JSX.Element}
 */
const Loader = (): JSX.Element => {
    return (
        <div className={`${styles.styles} grid min-h-[40rem] w-full place-items-center`}>
            <svg width="60" height="60" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="var(--color1)">
                <circle className="spinner_1KD7" cx="12" cy="3" r="0" />
                <circle className="spinner_1KD7 spinner_MJg4" cx="16.50" cy="4.21" r="0" />
                <circle className="spinner_1KD7 spinner_SeO7" cx="7.50" cy="4.21" r="0" />
                <circle className="spinner_1KD7 spinner_sj9X" cx="19.79" cy="7.50" r="0" />
                <circle className="spinner_1KD7 spinner_7he2" cx="4.21" cy="7.50" r="0" />
                <circle className="spinner_1KD7 spinner_WwCl" cx="21.00" cy="12.00" r="0" />
                <circle className="spinner_1KD7 spinner_4zOl" cx="3.00" cy="12.00" r="0" />
                <circle className="spinner_1KD7 spinner_vy2J" cx="19.79" cy="16.50" r="0" />
                <circle className="spinner_1KD7 spinner_kugV" cx="4.21" cy="16.50" r="0" />
                <circle className="spinner_1KD7 spinner_os1F" cx="16.50" cy="19.79" r="0" />
                <circle className="spinner_1KD7 spinner_WNEg" cx="7.50" cy="19.79" r="0" />
                <circle className="spinner_1KD7 spinner_l1Tw" cx="12" cy="21" r="0" />
            </svg>
        </div>
    );
};

export default Loader;
